export class ReferralService {
  constructor({ http }) {
    this.http = http;
  }

  async getCode({ languageIsoCode }) {
    try {
      const response = await this.http.API_AUTH.get(
        `referral/sponsor/code?LanguageIsoCode=${languageIsoCode}`
      );
      if (response.status === 200) {
        const { data } = response;
        return data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  async getInvitations({ languageIsoCode }) {
    try {
      const response = await this.http.API_AUTH.get(
        `referral/sponsor/invited?LanguageIsoCode=${languageIsoCode}`
      );
      if (response.status === 200) {
        const { data } = response;
        return data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }

  async sponsoredPromocode({
    email,
    referralCode,
    languageIsoCode,
    newsletter,
  }) {
    var formData = new FormData();
    formData.append("Email", email);
    formData.append("ReferralCode", referralCode);
    formData.append("LanguageIsoCode", languageIsoCode);
    formData.append("Newsletter", newsletter);
    try {
      let response = await this.http.API.post(
        "referral/sponsored/promocode",
        formData
      );
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw error;
    }
  }
}
